<template>
    <div class="NeedImpro">
        <div class="box">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="NeedImpro_h3 text-color">Вам может понадобится также</h3>
                </div>
                <div class="col-md-4">
                    <development/>
                </div>
                <div class="col-md-4">
                    <development/>
                </div>
                <div class="col-md-4">
                    <development/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import development from '@/components/development.vue'
export default {
  name: 'development.vue',
  components: {
    development,
  }
}
</script>
<style scoped>
/*****NeedImpro******/
.NeedImpro_h3 {
    font-size:40px;
    font-weight:600;
    line-height:47px;
    margin:
}
/*****NeedImpro******/
</style>