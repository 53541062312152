<template>
    <div class="HeaderCmsSistens">
            <div class="background_gradient">
                    <div class="box">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="box_header">
                                <h3 class="text-color header_h2">Джумла</h3>
                                <p class="text-color header_p_1">От небольшого модуля до разработки корпоративного портала</p>
                                <a class="home_btn">Узнать стоимость</a>
                                </div>
                            </div>
                            <div class="col-md-6">
                             <img src="@/assets/img/Product_page/Chel.svg" class="header_img">
                         </div>
                        </div>
                    </div>
            </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderProductCart',
  components: {

  }
}
</script>

<style scoped>
/*****HeaderCmsSismens******/
.header_img {
    min-width: 300px;
    position: absolute;
    max-height: 378px;
    right: 0px;
    bottom: 0;
}
.header_p_1 {
    font-size:30px;
    line-height:35px;
    margin:38px 0 40px 0;
}
.box_header {
    margin:120px 0;
}
.a_btn {
  margin:25px 36px 0 0;
}
.home_btn {
    padding: 13px 15px 13px 25px;
    background-color: #444BD3;
    color: #fff;
    border-radius: 6px;
    display: block;
    max-width: 422px;
    text-align: center;
}
.header_h2 {
    font-size:48px;
    line-height:57px;
    font-weight:bold;
    max-width:550px;
    margin:0;
}
.black .background_gradient {
  background:linear-gradient(119deg,#121127 0%,#BAA2FF 116%);
}
.white .background_gradient {
  background:linear-gradient(119deg,#BAA2FF 0%,#EDF9FF 100%);

}
@media(max-width:715px){
    .header_h2 {
        font-size:30px;
    }
    .header_p_1 {
        font-size:25px;
    }
    .home_btn {
        padding: 13px 63px;
    }
    .header_img {
      display:none;
    }
}
@media(max-width:772px){

    .header_img {
      display:none;
    }
}
/*****HeaderCmsSismens******/
</style>