<template>
    <div class="DescriptionImpro">
        <div class="box">
            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <h3 class="text-color DescriptionImpro_h3">On a mission to empower teams</h3>
                    <p class="text-color DescriptionImpro_p">Sales copywriting is all about getting users to make a purchase or sign up for a product or service. UX copywriting, on the other hand, is all about helping users reach a goal efficiently and effectively.</p>
                    <p class="text-color DescriptionImpro_p">There are overlaps between the two, for sure. But while sales copywriting is often about convincing a customer to do something, UX copywriting is about helping them do something they’re already trying to do. You don’t have to sell them on the goal, just help them achieve it.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
/*****DescriptionImpro******/
.DescriptionImpro_h3 {
    font-size:44px;
    line-height:60px;
    font-weight:700;
    margin:100px 0 0 0;
}
.DescriptionImpro_p {
    font-size:18px;
    line-height:32px;
    font-weight:400;
    margin:20px 0;
}
/*****DescriptionImpro******/
</style>