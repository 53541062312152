<template>
    <navigation/>
    <router-view class="white"/>
    <footers/>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import navigation from '@/components/navigation.vue'
import footers from '@/components/footer.vue'
export default {
  components: {
    navigation,
    footers,
  },
  data(){
    return{
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{

  ...mapMutations({
  }),

  ...mapActions({
  }),

  },

  computed:{
    ...mapGetters([
  ]),
  }
}
</script>

<style>
#app {
  font-family: 'Montserrat';
  text-align: left;
  color: #2c3e50;
}
body{
  margin:0px;
  padding:0px;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin:0 ;
}
.box {
  max-width: 1262px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px 0 15px;
}
a {
  cursor:pointer;
}
.flex {
  display:flex;
}
.justify-content {
  justify-content: space-between;
}
.black {
  background-color:#121127;
}
.black .text-color {
  color:#fff;
}
.white {
  background-color:#fff;
}
.white .text-color {
  color:#000;
}
.magasin_head_h3 {
  font-size:40px;
  margin:0;
}
@media(max-width:767px) {
  .magasin_head_h3 {
    font-size: 33px;
  }
}
</style>
