import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import './assets/css/bootstrap-grid.min.css'
import './assets/css/fonts.css'
import _  from 'lodash'
import VueLodash from 'vue-lodash'

createApp(App).use(router).use(store).use(VueAxios, axios).mount('#app')
