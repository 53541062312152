<template>
  <div class="home_page_magasine_modules">
    <div class="box">
        <h3 class="magasin_head_h3 text-color">Магазин модулей</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="max_widtch_456">
              <p class="magasin_head_text_p_1">Подключение в пару кликов</p>
              <p class="magasin_head_text_p_2 text-color">Все наши модули оттестированы и легко интегрируются c любыми CMS. Вам не потребуются специальные знания для их установки</p>
              <img src="@/assets/img/module_info.png" class="module_img">
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Удобная интеграция</p>
              </div>
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Не требует специальных навыков</p>
              </div>
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Подходит к любой CMS</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="max_widtch_456">
              <p class="magasin_head_text_p_1">Подключение в пару кликов</p>
              <p class="magasin_head_text_p_2 text-color">Все наши модули оттестированы и легко интегрируются c любыми CMS. Вам не потребуются специальные знания для их установки</p>
              <img src="@/assets/img/module_info.png" class="module_img">
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Удобная интеграция</p>
              </div>
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Не требует специальных навыков</p>
              </div>
              <div class="text_list_magasin text-color">
                <i class="text_list_magasin_i "></i>
                <p class="text_list_magasin_p">Подходит к любой CMS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

</script>

<style scoped>
/*****magasin_modules******/
.magasin_head_text_p_1 {
  font-size:20px;
  color:#444BD3;
}
.magasin_head_text_p_2 {
  font-size:16px;
  Line-height: 29px;
}
.module_img {
  width:100%;
  height:auto;
  margin-bottom:32px;
  border-radius:
}
.max_widtch_456 {
  max-width:456px;
}
.text_list_magasin {
  display:flex;
}
.text_list_magasin_i {
  display: block;
  height: 39%;
  background: url(@/assets/img/Shape.svg);
  background-size: 54%;
  background-repeat: no-repeat;
  background-color: #90ECF4;
  border-radius: 50%;
  padding: 12px 12px;
  margin-top: 6px;
  background-position: 6px;
}
.text_list_magasin_p {
  font-size:14px;
  margin:0 0 8px 0;
  padding: 10px 0 0 10px;
}
@media(max-width:767px) {
.max_widtch_456 {
  max-width:100%;
}
}
/*****magasin_modules******/
</style>