<template>
  <div class="ImprovementsPage">
    <div class="all_hild">
      <ImprovementsPageHeader/>
      <UnderNavImpro/>
      <DescriptionImpro/>
      <IllustrationImpro/>
      <NeedImpro/>
      <Feadback/>
      <footer_progect/>
    </div>
  </div>
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import ImprovementsPageHeader from '@/components/ImprovementsPage/ImprovementsPageHeader.vue'
import UnderNavImpro from '@/components/ImprovementsPage/UnderNavImpro.vue'
import DescriptionImpro from '@/components/ImprovementsPage/DescriptionImpro.vue'
import IllustrationImpro from '@/components/ImprovementsPage/IllustrationImpro.vue'
import NeedImpro from '@/components/ImprovementsPage/NeedImpro.vue'
import Feadback from '@/components/Feadback.vue'


export default {
  name:'ImprovementsPage',
  components: {
    ImprovementsPageHeader,
    UnderNavImpro,
    DescriptionImpro,
    IllustrationImpro,
    NeedImpro,
    Feadback,
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
}
</script>

<style scoped>

</style>

