<template>
    <div class="home_page">
    <div class="all_hild">
      <div class="background_gradient">
        <home_info/>
      </div>
      <information_about_modules/>
      <home_page_magasine_modules/>
      <div class="box populyar_all">
        <h3 class="magasin_head_h3 text-color">Популярные модули</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <product_cart/>
          </div>
          <div class="col-lg-4 col-md-6">
            <product_cart/>
          </div> 
          <div class="col-lg-4 col-md-6">
            <product_cart/>
          </div>
          <div class="col-md-12">
            <div class="btn_magasin">
              <a class="btn_magasin_a">Перейти в магазин</a>
            </div>
          </div>  
        </div>
      </div>
      <home_page_services/>
      <home_page_progects/>
      <feedback_form/>
      <home_page_achievements/>
    </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import home_info from '@/components/Home/home_info.vue'
import product_cart from '@/components/product_cart.vue'
import feedback_form from '@/components/feedback_form.vue'
import information_about_modules from '@/components/Home/information_about_modules.vue'
import home_page_magasine_modules from '@/components/Home/home_page_magasine_modules.vue'
import home_page_services from '@/components/Home/home_page_services.vue'
import home_page_progects from '@/components/Home/home_page_progects.vue'
import home_page_achievements from '@/components/Home/home_page_achievements.vue'

export default {
  name:'Home',
  components: {
  	product_cart,
    feedback_form,
    home_info,
    information_about_modules,
    home_page_magasine_modules,
    home_page_services,
    home_page_progects,
    home_page_achievements,
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
}
</script>

<style scoped>
.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #121127);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #EDF9FF);
  background-position: right;
}
/*****modules_cart_magasine******/
.btn_magasin {
  margin: 66px 0 128px 0;
  text-align:center;
  color:#fff;
}
.btn_magasin_a {
  padding:13px 116px;
  font-size:16px;
  background-color:#444BD3;
  border-radius:8px;
}
/*****modules_cart_magasine******/

.populyar_all {
  margin-top:100px;
}

@media(max-width:767px) {
  .btn_magasin_a {
    padding: 13px 20px;
  }
}
</style>

