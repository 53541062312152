import { createStore } from 'vuex'
import axios from 'axios'

//Modules
import options from './options'
import shopModulesProduct from './ShopTradeModules/shopModulesProduct'



export default createStore({
  modules: {
    options,
    shopModulesProduct,

  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
})
