<template>
  <div class="CmsSismens">
    <div class="all_hild white">
      <HeaderCmsSistens/>
      <IdealFor/>
      <InfoCmsSistens/>
      <Feadback/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCmsSistens from '@/components/CmsSistens/HeaderCmsSistens.vue'
import IdealFor from '@/components/CmsSistens/IdealFor.vue'
import InfoCmsSistens from '@/components/CmsSistens/InfoCmsSistens.vue'
import Feadback from '@/components/Feadback.vue'
export default {
  name: 'CmsSismens',
  components: {
    HeaderCmsSistens,
    IdealFor,
    InfoCmsSistens,
    Feadback,

  }
}
</script>

<style scoped>

</style>