<template>
  <div class="home_page_progects">
    <div class="progects">
        <div class="box">
          <div class="row">
            <div class="col-xl-5">
              <h3 class="magasin_head_h3 text-color">Наши Проекты</h3>
              <p class="progects_p text-color">В нашем портфолио большое количесво успешных проектов. Вы можете посмотреть лучшие из наших работ или созвониться и обсудить задачи Вашего бизнеса.</p>
              <a class="progects_btn">Подробнее</a>
            </div>
            <div class="offset-xl-2 col-xl-5">
              <div class="background_display_group">
                  <div class="display_img">
                    <img src="@/assets/img/services-img1.png" class="display_img_under">
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

</script>

<style scoped>
/*****prigects******/
.progects_p {
  Line-height:32px;
  font-size:18px;
  margin:32px 0 38px 0;
}
.progects_btn {
  padding:10px 87px;
  color:#fff;
  background:#444BD3;
  font-size:16px;
  border-radius:8px;
}
.progects {
  margin-top:200px;
}
/*****для адаптации поменять background-size******/
.background_display {
  max-width:482px;
  max-height:347px;
}
.background_display_group {
  background: url('@/assets/img/some/display.svg') no-repeat;
  max-width: 484px;
  min-height: 348px;
  background-size: 448px;
  position: relative;
}
.display_img {
  max-width: 364px;
  height: 206px;
}
.display_img_under {
  width:100%;
  height:100%;
  background-color:#fff;
  margin:18px 0 0 42px
}
/*****prigects******/
/*****prigects******/
@media (max-width:1200px) {
  .background_display_group {
    margin-top:50px;
  }
}
@media (max-width:767px){
  .progects {
    margin-top:100px;
  }
}
@media (max-width:472px) {
  .background_display_group {
    background-size: 350px;
  }
  .display_img_under {
    margin: 13px 0 0 33px;
  }
  .display_img {
    max-width: 285px;
    height: 161px;
  }
}
@media (max-width:378px) {
  .display_img {
    max-width: 239px;
    height: 136px;
  }
  .display_img_under {
    margin: 11px 0 0 27px;
  }
  .background_display_group {
    background-size: 295px;
  }
}
.@media (max-width:325px) {
  .display_img {
    max-width: 213px;
    height: 119px;
  }
  .display_img_under {
    margin: 11px 0 0 25px;
  }
  .background_display_group {
    background-size: 263px;
  }
}
/*****prigects******/
</style>