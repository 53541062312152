<template>
    <div class="IdealFor">
        <div class="box">
            <div class="row">
            <div class="col-md-12">
                <h3 class="text-color IdealFor_h3">Джумла идеально подойдет для:</h3>
            </div>
            <div class="col-md-4">
                <div class=" IdealFor_cart">
                    <i class="iconcart"></i>
                    <h3 class="text-color IdealFor_h3_2">Competitive exchange rate</h3>
                    <p class="text-color IdealFor_p_1">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                    <div class="flex IdealFor_flex">
                        <p class="IdealFor_p_2">ПОДРОБНЕЕ</p><i class="modules_cart_icon_2"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class=" IdealFor_cart">
                    <i class="iconcart"></i>
                    <h3 class="text-color IdealFor_h3_2">Competitive exchange rate</h3>
                    <p class="text-color IdealFor_p_1">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                    <div class="flex IdealFor_flex">
                        <p class="IdealFor_p_2">ПОДРОБНЕЕ</p><i class="modules_cart_icon_2"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class=" IdealFor_cart">
                    <i class="iconcart"></i>
                    <h3 class="text-color IdealFor_h3_2">Competitive exchange rate</h3>
                    <p class="text-color IdealFor_p_1">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                    <div class="flex IdealFor_flex">
                        <p class="IdealFor_p_2">ПОДРОБНЕЕ</p><i class="modules_cart_icon_2"></i>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
/*****IdealFor******/
.modules_cart_icon_2 {
  display:block;
  width:19px;
  height:19px;
  margin: 2px 0 0 10px;
  background:url("../../assets/img/line.svg") no-repeat;
  background-size: 100%;
}
.iconcart {
    display:block;
    width:48px;
    height:48px;
    background:url("../../assets/img/Product_page/iconcart.svg") no-repeat;
    background-size: 100%;
}
.IdealFor_h3 {
    font-size:30px;
    margin:60px 0;
    line-height:35px;
}
.IdealFor_cart {
    box-shadow: 4px 5px 12px 6px rgb(34 60 80 / 40%);
    padding:56px 24px;
    margin-bottom:30px;
}
.IdealFor_h3_2 {
    padding: 24px 0 24px 0;
    font-size:16px;
    font-weight:700;
    margin:0;
}
.IdealFor_p_1 {
    font-size:16px;
    padding:0 24px 24px 0;
    margin:0;
}
.IdealFor_p_2 {
    color:#444BD3;
    margin:0;
}
.IdealFor_flex {

}
/*****IdealFor******/
</style>