<template>
  <div class="information_about_modules">
    <div class="box">
        <div class="row">
          <div class="col-md-4">
            <div class="modules_cart">
              <i class="modules_cart_icon_1 icon-color_1"></i>
              <p class="modules_cart_p_1 text-color">Любые CMS</p>
              <p class="modules_cart_p_2 text-color">Наши модули интегрируются в продукты на всех популярных CMS</p>
              <div class="flex"><a class="modules_cart_a">ПОДРОБНЕЕ</a> <i class="modules_cart_icon_2"></i></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="modules_cart">
              <i class="modules_cart_icon_1 icon-color_1"></i>
              <p class="modules_cart_p_1 text-color">Любые CMS</p>
              <p class="modules_cart_p_2 text-color">Наши модули интегрируются в продукты на всех популярных CMS</p>
              <div class="flex"><a class="modules_cart_a">ПОДРОБНЕЕ</a><i class="modules_cart_icon_2"></i></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="modules_cart">
              <i class="modules_cart_icon_1 icon-color_1"></i>
              <p class="modules_cart_p_1 text-color">Любые CMS</p>
              <p class="modules_cart_p_2 text-color">Наши модули интегрируются в продукты на всех популярных CMS</p>
              <div class="flex"><a class="modules_cart_a">ПОДРОБНЕЕ</a> <i class="modules_cart_icon_2"></i></div>
            </div>
          </div>
        </div>
        </div>
      </div>
</template>

<script>

</script>

<style scoped>
.black .icon-color_1 {
  background:url("@/assets/img/icon_black.svg") no-repeat;
  background-size: 100%;
}
.white .icon-color_1 {
  background:url("@/assets/img/icon_white.svg") no-repeat;
  background-size: 100%;
}
/*****information_about_modules******/

.information_about_modules {
  margin-top:87px;
  margin-bottom:78px;
}
.modules_cart {
  max-width:300px;
}
.modules_cart_icon_1 {
  display:block;
  width:60px;
  height:60px;
}
.modules_cart_p_1 {
  margin:14px 0;
  font-size:20px;
  font-weight:600; 
}
.modules_cart_p_2 {
  margin:0 0 25px 0;
  font-size:20px;
}
.modules_cart_a {
  color:#444BD3;
  font-size:14pxp;
}
.modules_cart_icon_2 {
  display:block;
  width:19px;
  height:19px;
  margin: 2px 0 0 10px;
  background:url("@/assets/img/line.svg") no-repeat;
  background-size: 100%;
}
@media(max-width:767px){
  .modules_cart{
    max-width: none;
    margin-bottom:20px;
  }
}
/*****information_about_modules******/
</style>