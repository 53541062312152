import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'


export default ({
  state: {
  	categories:[
  	{'id':1, 'category':'Разработка', "category_second":[{"id":1,'category':'Битрикс'},{"id":2,'category':'Тильда'}]},
  	{"id":2,"category":"Под ключ","category_second":[{"id":3,'category':'Доработки'},{"id":4,'category':'Word press'},{"id":5,'category':'Bitrix'},{"id":6,'category':'Хуйня'}]},
  	],
  	cats_id:1,
  },
  modules: {
    options,
  },
  mutations: {
  	seach_second_categories(state, cats_id){
  		state.cats_id = cats_id
  		// console.log(state.cats_id)
  	}
  },
  actions: {
  },
  getters: {
  	GET_ALL_CATEGORIES(state){
  		return state.categories
  	},
  	GET_SECOND_CATEGORIES(state){
  		return state.categories.filter(cats => {return cats.id == state.cats_id})
  	}
  },
})
