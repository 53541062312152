<template>
  <div class="shopModule">
    <div class="all_hild">
      <div class="background_gradient">
        <home_info/>
      </div>
      <product_cart/>
      <feedback_form/>
      <why_we/> 
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import product_cart from '@/components/product_cart.vue'
import feedback_form from '@/components/feedback_form.vue'
import navigation from '@/components/navigation.vue'
import why_we from '@/components/why_we.vue'
import home_info from '@/components/home_info.vue'
export default {
  name: 'shopModule',
  components: {
    product_cart,
    feedback_form,
    navigation,
    why_we,
    home_info,
  }
}
</script>

<style scoped>
.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg,#121127 ,90%, #E9F8FB);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #E9F8FB,83%, #FFF1F1);
  background-position: right;
}
/*****header_info*****/
.header_info {
  padding:0 0 147px 0;
}
.header_module_h3 {
  font-size:48px;
  line-height:57px;
  font-weight: 500;
  margin:140px 0 105px 0;
}
.find_cost {
  color:#fff;
  background:#444BD3;
  padding:13px 122px;
  border-radius:8px;
  margin-bottom:172px;
}
/*****header_info*****/

/*****all_products*****/
.mini_nav {
  margin:45px 0 59px 0;
}
.mini_nav_1 {
  font-size:18px;
}
.mini_nav_2 {
  color:#444BD3;
  font-size:18px;
}
.all_product_h3 {
  font-size:40px;
  font-weight:500;
}
.all_product_a {
  color: #444BD3;
  font-size:20px;
}
/*****all_products*****/

/*****modules_cart_magasine******/
.btn_magasin {
  margin:118px 0 69px 0;
  text-align:center;
  color:#fff;
}
.btn_magasin_a {
  padding:13px 116px;
  font-size:16px;
  background-color:#444BD3;
  border-radius:8px;
}
.modules_cart_magasine_p_2 {
  margin:0;
  padding-top:32px;
}
.modules_cart_magasine_prise_p {
  margin:0;
  font-size:20px;
}
.modules_cart_magasine {
  box-shadow: 4px 5px 12px 6px rgba(34, 60, 80, 0.40);
  margin-top:48px;
}
.modules_cart_magasine_img {
  width:100%;
  height:auto;
}
.modules_cart_magasine_prise {
  display:flex;
  justify-content: space-between;
  padding:74px 36px 33px 32px;
}
.modules_cart_magasine_prise_a {
  font-size:14px;
  color:#444BD3;
  padding-top:1px;
}
.modules_cart_magasine_prise_flex {
  display:flex;
  padding-top:3px;
}
.modules_cart_magasine_p_1 {
  margin:0px;
  font-size:20px;
  font-weight: 700;
  color:#444BD3;
}
.modules_cart_magasine_text {
  padding: 52px 8px 0 32px;
}

/*****modules_cart_magasine******/

@media (max-width:439px) {
  .find_cost {
    padding:13px 47px;
  }
}
</style>