import { createStore } from 'vuex'
import axios from 'axios'

//Modules



export default createStore({
  modules: {
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
})
