<template>
  <div class="about">
    <div class="all_hild">
      <div class="background_gradient">
        <home_info/>
      </div>
      <div class="box all_product">
        <div class="flex mini_nav">
          <a class="mini_nav_1 text-color">Главная/</a>
          <a class="mini_nav_2">Магазин доработок</a> 
        </div>
        <h3 class="all_product_h3 text-color">Магазин модулей</h3>
        <div class="flex">
        <a class="all_product_a">Выберите подкатегорию</a>
        <i></i>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <development/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <development/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <development/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <development/>
          </div>
        </div>
      </div>
      <feedback_form/>
      <why_we/>
      <footer_progect/>  
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import development from '@/components/development.vue'
import feedback_form from '@/components/feedback_form.vue'
import navigation from '@/components/navigation.vue'
import why_we from '@/components/why_we.vue'
import home_info from '@/components/home_info.vue'
export default {
  name: 'second_page',
  components: {
    development,
    feedback_form,
    navigation,
    why_we,
    home_info,
  }
}
</script>

<style scoped>
a {
  cursor:pointer;
}
.flex {
  display:flex;
}
.black {
  background-color:#121127;
}
.black .text-color {
  color:#fff;
}
.white {
  background-color:#fff;
}
.white .text-color {
  color:#000;
}
.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #121127);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #EDF9FF);
  background-position: right;
}
/*****all_products*****/
.mini_nav {
  margin:45px 0 59px 0;
}
.mini_nav_1 {
  font-size:18px;
}
.mini_nav_2 {
  color:#444BD3;
  font-size:18px;
}
.all_product_h3 {
  font-size:40px;
  font-weight:500;
}
.all_product_a {
  color: #444BD3;
  font-size:20px;
}
/*****all_products*****/

@media (max-width:439px) {
  .find_cost {
    padding:13px 47px;
  }
}
</style>