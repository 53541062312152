<template>
<div class="feedback_form">
        <div class="box">
          <div class="row">
            <div class="col-lg-12">
              <div class="box_feedback_form">
                <div class="row">
                  <div class="col-lg-6">
                    <h3 class="feedback_form_h3">Давайте обсудим Ваш проект?</h3>
                    <p class="feedback_form_p">Оставьте контактные данные и мы перезвоним Вам в течение часа</p>
                  </div>
                  <div class="col-lg-6">
                    <div class="feedback_form_upper">
                      <input type="" name="" class="feedback_form_input" placeholder="Номер телефона" maxlength="15">
                      <a class="feedback_form_a">Созвониться</a>
                    </div>
                    <p class="feedback_form_p_1"><span>Заполняя форму Вы соглашаетесь с</span> политикой конфиденциальности</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>

</script>

<style scoped>
/*****feedback_form*****/
.feedback_form {
  margin:50px 0 50px 0;
}
.feedback_form_p_1 span {
  opacity: 56%;
}
.feedback_form_p_1 {
  color:#fff;
  font-size:12px;
  margin:12px 0 56px 0;
}
.feedback_form_input {
  width: 200px;
    height: 48px;
    border-radius: 10px;
    padding: 0;
    background: #201F37;
    border: solid 1px rgba(255, 255, 255, .5);
    padding-left:10px;
    outline: none !important;
    color:#fff;
    opacity:56%;
    font-family: 'MONTSERRAT';
}
.feedback_form_upper {
  margin-top:56px;
}
.feedback_form_a {
  color:#fff;
  padding:14px 32px;
  background-color:#444bd3;
  border-radius:8px;
  margin-left:20px;
}
.box_feedback_form {
  width:1128px;
  background-color:#201F37;
  border-radius:16px;
  margin:0 auto 0 auto;
}
.feedback_form_h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #fff;
  margin: 56px 0 0 34px;
}
.feedback_form_p {
  color:#fff;
  font-size:14px;
  margin:12px 0 0 34px;
}
@media (max-width:1167px) {
  .box_feedback_form {
    width:100%;
  }
  .feedback_form_upper {
    margin-left:34px;
  }
  .feedback_form_p_1 {
    margin-left:34px;
  }
}
@media (max-width:472px) {
  .feedback_form_input {
    margin-bottom: 30px;
    margin-right:20px;
  }
  .feedback_form_a {
    margin-left:0;
  }
  .feedback_form_p_1 {
    margin-top:20px;
  }
  .feedback_form_h3 {
    font-size:23px;
  }
  .feedback_form_p {
    margin: 12px 20px 0 34px;
  }
}
@media (max-width:991px) {
.feedback_form_upper {
    margin-top: 23px;
  }
}
/*****feedback_form*****/
</style>