<template>
    <div class="Feadback">
        <div class="box">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="text-color Feadback_h3">Sign up for our newsletter</h3>
                    <p class="slider_p">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint velit officia consequat duis enim velit mollit.</p>
                </div>
                <div class="col-md-6">
                    <div>
                        <input class="Feadback_input" placeholder="Enter email address">
                        <a class="Feadback_a">Отправить</a>
                    </div>
                    <p class="text-color Feadback_p">We care about the protection of your data. read our <span class="Feadback_span">Privacy policy</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
/*****Feadback******/
.Feadback_p {
    margin:12px 0 0 0;
}
.Feadback_h3 {
    font-size:32px;
    line-height:44px;
    margin:0;
}
.black .slider_p {
    color:#fff;
    opacity:56%;
    font-size:16px;
    line-height:36px;
    margin:12 0 0 0;
}
.white .slider_p {
    color:#121127;
    opacity:56%;
    font-size:16px;
    line-height:36px;
    margin:12 0 0 0;
}
.Feadback {
    padding:80px 0 90px 0;
}
.Feadback_input {
    width:267px;
    height:52px;
    border-radius:8px;
    padding-left:16px;
    font-size:14px;
    color:#000;
    outline: 0;
    outline-offset: 0;
    margin-right:16px;
    border:1px solid #909090;
    font-family: 'Montserrat';
}
.Feadback_a {
    padding:14px 32px;
    font-size:16px;
    background:#444BD3;
    color:#fff;
    border-radius:8px;
}
.Feadback_span {
    color:#4E8AF4;
}
@media(max-width:951px) {
    .Feadback_input {  
        margin-bottom:30px;
    }
    .Feadback_p {
    margin-top:30px;
    }
}
@media(max-width:400px) {
    .Feadback_input {
        margin-right:0;
        width:200px;
    }
}
/*****Feadback******/
</style>