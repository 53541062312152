<template>
    <div class="InfoCmsSistens">
        <div class="box">
            <div class="row">
                <div class="col-md-5">
                    <h3 class="text-color InfoCmsSistens_h3">Особенности CMS Джумла</h3>
                    <p class="text-color InfoCmsSistens_p_1">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                    <p class="text-color InfoCmsSistens_p_2">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is Workspace.</p>
                    <p class="text-color InfoCmsSistens_p_1">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                    <p class="text-color InfoCmsSistens_p_2">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is Workspace.</p>
                </div>
                <div class="col-md-7">
                   <img class="InfoCmsSistens_img" src="../../assets/img/Product_page/html.svg">
                </div>
            </div> 
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
/*****InfoCmsSistens******/
.InfoCmsSistens {
    margin-top:100px;
}
.InfoCmsSistens_h3 {
    font-size:30px;
    line-height:35px;
    margin:0;
    font-weight:400;
}
.InfoCmsSistens_p_1 {
    margin:32px 0 16px;
    font-size:20px;
    font-weight:700;
    line-height:28px;
}
.InfoCmsSistens_p_2 {
    margin:0;
    opacity:50%;
    font-size:14px;
    line-height:25px;
}
.InfoCmsSistens_img {
    width:100%;
    height:auto;
}
@media (max-width:600px) {
    .InfoCmsSistens {
        margin-top:30px;
    }
}
/*****InfoCmsSistens******/
</style>