<template>
    <div class="IllustrationImpro">
        <div class="box">
            <div class="row">
                <div class="col-md-5">
                    <h3 class="text-color IllustrationImpro_h3">Digital design is like painting, except the paint never dries.</h3>
                    <p class="text-color IllustrationImpro_p_1">Simplify your integration using Stripe Checkout. It dynamically adapts to your customer’s device and location to increase conversion.</p>
                    <div class="flex"><img src="../../assets/img/Product_page/line_2.svg" class="IllustrationImpro_img"><p class="text-color IllustrationImpro_p_2">Upgrade your customer experience instantly</p></div>
                    <div class="flex"><img src="../../assets/img/Product_page/line_2.svg" class="IllustrationImpro_img"><p class="text-color IllustrationImpro_p_2">Upgrade your customer experience instantly</p></div>
                    <div class="flex"><img src="../../assets/img/Product_page/line_2.svg" class="IllustrationImpro_img"><p class="text-color IllustrationImpro_p_2">Upgrade your customer experience instantly</p></div>
                </div>
                <div class="offset-md-1 col-md-6">
                    <img src="../../assets/img/Product_page/analysis.svg" class="IllustrationImpro_img_2">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
/*****IllustrationImpro******/
.IllustrationImpro_h3 {
    font-size:40px;
    font-weight:700;
    line-height:56px;
    margin:40px 0 0 0;
}
.IllustrationImpro_p_1 {
    font-size:18px;
    line-height:32px;
    font-weight:400;
    margin:20px 0 48px 0;
}
.IllustrationImpro_p_2 {
    font-size:16px;
    line-height:28px;
    font-weight:400;
    margin:0 0 24px 0;
}
.IllustrationImpro_img {
    width:20px;
    height:20px;
    margin:0 12px 0 0;
}
.IllustrationImpro_img_2 {
    width:100%;
    height:auto;
}
/*****IllustrationImpro******/
</style>