<template>
  <div class="home_page_achievements">
    <div class="our_achievements">
        <div class="box">
          <div class="row">
            <div class="col-lg-6">
              <p class="our_achievements_p">КОРОТКО О НАШЕЙ РАБОТЕ</p>
              <h3 class="our_achievements_h3 text-color">Мы готовы предложить лучшие условия для сотрудничества </h3>
              <p class="our_achievements_p_2 text-color">Наши специалисты имеют большой опыт в разных сферах бизнеса, поэтому мы можем подобрать для Вас идеальное решение</p>
              <div class="row">
                <div class="col-md-6">
                  <h3 class="our_achievements_h3_2">16+</h3>
                  <p class="our_achievements_p_3 text-color">сотрудников готовы работать над Вашим проектом</p>
                </div>
                <div class="col-md-6">
                  <h3 class="our_achievements_h3_2">126</h3>
                  <p class="our_achievements_p_3 text-color">лет, общий опыт работы нашей команды</p>
                </div>
                <div class="col-md-6">
                  <h3 class="our_achievements_h3_2">76</h3>
                  <p class="our_achievements_p_3 text-color">проектов были реализованы нами только в этом году</p>
                </div>
                <div class="col-md-6">
                  <h3 class="our_achievements_h3_2">12M+</h3>
                  <p class="our_achievements_p_3 text-color">строчек кода пишется нашими сотрудниками ежегодно</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

</script>
<style scoped>
.black .our_achievements {
    background:linear-gradient(to right, #121127 0%, #121127 calc(80% - 172px), transparent calc(212% - 122px), transparent calc(115% + 41px), #121127 calc(66% + 160px), #121127 112%), url('@/assets/img/some/display_2.svg') no-repeat;
  background-position:right bottom;
}
.white .our_achievements {
    background:linear-gradient(to right, #fff 0%, #fff calc(80% - 166px), transparent calc(101% - 114px), transparent calc(102% + 30px), #fff calc(50% + 160px), #fff 100%), url('@/assets/img/some/display_2.svg') no-repeat;
  background-position:right bottom;
}
/*****our_achievements*****/
.our_achievements {
  height: 100%;
  width: 100%;
  margin-top:100px;
}
.our_achievements_p {
  color:#444BD3;
  font-size:14px;
  font-weight:700;
  margin:96px 0 0 0;
}
.our_achievements_h3 {
  font-size:40px;
  font-weight:700;
  line-height:56px;
  margin:12px 0 16px 0;
}
.our_achievements_p_2 {
  font-size:18px;
  margin:0 0 40px 0;
  line-height:32px;
  opacity:50%;
}
.our_achievements_h3_2 {
  color:#444BD3;
  font-size:24px;
  margin:0;
}
.our_achievements_p_3 {
  margin:0 0 45px 0;
  font-size:14px;
  opacity:50%;
  line-height:25px;
}
@media(max-width:991px) {
  .our_achievements_h3 {
  font-size:27px;
  }
  .our_achievements_p_3 {
    margin:0 0 20px 0;
  }
}
@media(max-width:400px) {
  .our_achievements_p_2 {
    font-size:14px;
  }
  .our_achievements_h3 {
  font-size:23px;
  }
}
/*****our_achievements*****/
</style>