import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'
import shopModulesCategories from './shopModulesCategories'
import _  from 'lodash'
import VueLodash from 'vue-lodash'


export default ({
  state: {
  	ModulesProduct:[
  	{'id':1, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'22 000', 'category_first':1, 'category_second':2, 'key':'Привет Пока'},
  	{'id':2, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'18 000', 'category_first':2, 'category_second':3, 'key':'Пока'},
  	{'id':3, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'26 000', 'category_first':1, 'category_second':1, 'key':'Новый'},
  	{'id':4, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'26 000', 'category_first':2, 'category_second':5, 'key':'пять Пока'},
  	{'id':5, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'22 000', 'category_first':2, 'category_second':5, 'key':'2312 Привет'},
  	{'id':6, 'img':'assets/img/services-img1.png', 'tittle':'Меню ресторана с умным фильтром', 'description':'Все наши модули оттестированы и легко нтегрируются c любыми CMS. Вам не потребуются специальные знания для их установки', 'price':'21 000', 'category_first':2, 'category_second':4, 'key':'25512'},
  	],
  	activeFirstCats:1,
  	activeSecondCats:1,
  	pageSize:1,
  	pageCount: 0,
  	items:[],
  	paginationMassive:null,
  	search:'',
  },
  modules: {
    options,
    shopModulesCategories,
  },
  mutations: {
  	active_first_categories(state, catid){
  		state.activeFirstCats = catid
  		// console.log(state.activeFirstCats)
  		// console.log()
  	},
  	active_second_categories(state, catid){
  		state.activeSecondCats = catid
  	},
  	get_first_cats(state){
  		let data = state.shopModulesCategories.categories.filter(item => { return item.id == state.activeFirstCats})
  		data = data[0].category_second
  		state.activeSecondCats = data[0].id
  	},
  	itemsPaginate(state, data){
  		if (data.search == false){
  			state.paginationMassive = state.ModulesProduct.filter(item => {return item.category_first == state.activeFirstCats && item.category_second == state.activeSecondCats})
	        state.pageCount = Math.ceil(state.paginationMassive.length / state.pageSize)
	        state.paginationMassive = _.chunk(state.paginationMassive, state.pageSize)
	        state.items = state.paginationMassive[data.page -1] || state.paginationMassive[0]
  		} if(data.search == true) {
  			state.paginationMassive = state.ModulesProduct.filter(item => {return item.key.toLowerCase().indexOf(state.search.toLowerCase()) > -1})
  			state.pageCount = Math.ceil(state.paginationMassive.length / state.pageSize)
	        state.paginationMassive = _.chunk(state.paginationMassive, state.pageSize)
	        state.items = state.paginationMassive[data.page -1] || state.paginationMassive[0]
  		}
        // console.log(state.items)
        // console.log(data.page)
      },
	paginationItems(state, page){ 
      state.items = state.paginationMassive[page -1] || state.paginationMassive[0]
      // console.log(state.items)
	},

  },
  actions: {
  	FILTERED_MODULES_PRODUCT({state, commit}, data) {
		commit('itemsPaginate', data)
		// console.log(data.page)
	}
  },
  getters: {
  	GET_MODULES_PRODUCT(state){
  		return state.items
  	},
  },
})
