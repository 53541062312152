<template>
  <div class="Product_page">
    <div class="all_hild white">
      <HeaderProductCart/>
      <UnderNav/>
      <SimilarModules/>
      <Feadback/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderProductCart from '@/components/Product_page/HeaderProductCart.vue'
import UnderNav from '@/components/Product_page/UnderNav.vue'
import SimilarModules from '@/components/Product_page/SimilarModules.vue'
import Feadback from '@/components/Feadback.vue'

export default {
  name: 'Product_page',
  components: {
    HeaderProductCart,
    UnderNav,
    SimilarModules,
    Feadback,

  },
  data(){
    return {
      SliderProductCart:true,
    }
  }
}
</script>

<style scoped>

</style>