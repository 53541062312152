<template>
    <div class="HeaderProductCart">
            <div class="background_gradient">
                    <div class="box">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="box_header">
                                <h3 class="text-color header_h2">Меню ресторана с умным фильтром</h3>
                                <p class="text-color header_p_1">Цена 26 000 руб.</p>
                                <a class="home_btn">Проверить</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
  </div>
</template>

<script>
import navigation from '@/components/navigation.vue'
export default {
  name: 'HeaderProductCart',
  components: {
    navigation,
  }
}
</script>

<style scoped>
/*****HeaderProductCart******/
.header_p_1 {
    font-size:30px;
    line-height:35px;
    margin:38px 0 40px 0;
}
.box_header {
    margin:120px 0;
}
.a_btn {
  margin:25px 36px 0 0;
}
.home_btn {
  padding: 13px 122px;
  background-color:#444BD3;
  color:#fff;
  border-radius:6px;
}
.header_h2 {
    font-size:48px;
    line-height:57px;
    font-weight:bold;
    max-width:550px;
    margin:0;
}
.black .background_gradient {
  background:url("@/assets/img/Product_page/Chel.svg")no-repeat, linear-gradient(119deg,#121127 0%,#BAA2FF 116%);
  background-position: right 0px bottom 0px;
  background-size: 330px, auto, cover;
}
.white .background_gradient {
  background:url("@/assets/img/Product_page/Chel.svg")no-repeat, linear-gradient(119deg,#BAA2FF 0%,#EDF9FF 100%);
  background-position: right 0px bottom 0px;
  background-size: 330px, auto, cover;
}
@media(max-width:715px){
   .black .background_gradient {
  background-size: 200px, auto, cover;
}
.white .background_gradient {
  background-size: 200px, auto, cover;
} 
}
@media(max-width:715px){
    .header_h2 {
        font-size:30px;
    }
    .header_p_1 {
        font-size:25px;
    }
    .home_btn {
        padding: 13px 63px;
    }
}
/*****HeaderProductCart******/
</style>