<template>
        <div class="box all_product">
        <div class="flex mini_nav">
          <a class="mini_nav_1 text-color">Главная/</a>
          <a class="mini_nav_2">Магазин доработок</a> 
        </div>
        <!-- @click="" -->
        <h3 class="all_product_h3 text-color">Магазин модулей <input class="search_modules" v-model="this.$store.state.shopModulesProduct.search" @input="inputChange"  type="text" placeholder="Поиск"></h3> 
        <h1>{{this.searchActive}}</h1>
<!--         <div class="flex">
        <a class="all_product_a">Выберите подкатегорию</a>
        <i></i>
        </div> -->
        <div class="search_categories">
          <h3 class="search_tittle">Категории</h3>
          <div class="serch_categories_frist"><i @click="serchCats = !serchCats" :class="{ico_open:!serchCats, ico_close:serchCats }"></i><ul v-if="serchCats">

            <li @click=" active_first_categories(categories.id), get_first_cats(), this.FILTERED_MODULES_PRODUCT({'page':this.page, 'search':false}),  activeCats = index, activeCatsSec = 0, seach_second_categories(categories.id)" v-for="(categories, index ) of GET_ALL_CATEGORIES" :key="categories.id" :class="{active:activeCats === index}">{{categories.category}}</li>

          </ul></div>
          <h3 class="search_tittle">Подкатегории</h3>
          <div class="serch_categories_second"><i @click="searchCatsSec = !searchCatsSec" :class="{ico_open:!searchCatsSec, ico_close:searchCatsSec }"></i><ul v-if="searchCatsSec">
            <template v-for="(categories, index ) of GET_SECOND_CATEGORIES" :key="categories.id">
            
            <li @click="active_second_categories(category.id),this.FILTERED_MODULES_PRODUCT({'page':this.page, 'search':false}), activeCatsSec = index" v-for="(category, index ) of categories.category_second" :key="categories.id" :class="{active:activeCatsSec === index}">{{category.category}}</li>

          </template>
        </ul></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4" v-for="(moudels, index) of GET_MODULES_PRODUCT" :key="moudels.id">
            <div class="product_cart">
                <div class="modules_cart_magasine">
                  <img src="../assets/img/services-img1.png" class="modules_cart_magasine_img">
                  <div class="modules_cart_magasine_text">
                   <p class="modules_cart_magasine_p_1">{{moudels.tittle}}</p>
                   <p class="modules_cart_magasine_p_2 text-color">{{moudels.description}}</p> 
                  </div>
                  <div class="modules_cart_magasine_prise">
                    <p class="modules_cart_magasine_prise_p text-color">{{moudels.price}} руб</p>
                    <div class="modules_cart_magasine_prise_flex"><a class="modules_cart_magasine_prise_a">ПОДРОБНЕЕ</a><i class="modules_cart_icon_2"></i></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <paginate
              v-model="page"
              :page-count="this.$store.state.shopModulesProduct.pageCount"
              :click-handler="paginationItems"
              :prev-text="'Назад'"
              :next-text="'Далее'"
              :container-class="'pagination'"
              :page-class="'page-item'"/>
            </div>
          </div>
        </div>
      </div>
  
</template>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import paginate from '@/components/paginate.vue'
export default {
  name:'all_product',
  components: {
    paginate,
  },
  data(){
    return{
      serchCats:true,
      searchCatsSec:true,
      activeCats:0,
      activeCatsSec:0,
      page: + this.$route.query.page || 1,
    }
  },
  mounted(){
    console.log(this.GET_MODULES_PRODUCT)
    this.get_first_cats()
    this.FILTERED_MODULES_PRODUCT({'page':this.page, 'search':false})
    // this.itemsPaginate(this.page)
    this.paginationItems(this.page)
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
    seach_second_categories:'seach_second_categories',
    active_first_categories:'active_first_categories',
    active_second_categories:'active_second_categories',
    get_first_cats:'get_first_cats',
    itemsPaginate:'itemsPaginate',
    paginationItems:'paginationItems',
  }),
  ...mapActions({
    FILTERED_MODULES_PRODUCT:'FILTERED_MODULES_PRODUCT'
  }),
  inputChange(e) {
      // console.log(e.target.value);
      this.FILTERED_MODULES_PRODUCT({'page':this.page, 'search':true})
  },
  // paginationItems(page){
  //     this.page = page   
  //     this.items = this.paginationMassive[this.page -1] || this.paginationMassive[0]
  //     console.log(this.items)
  //   },
  },
  computed:{
    ...mapGetters([
      'GET_ALL_CATEGORIES',
      'GET_SECOND_CATEGORIES',
      'GET_MODULES_PRODUCT'
  ]),
    searchActive:function(){
      // this.FILTERED_MODULES_PRODUCT()
      return this.$store.state.shopModulesProduct.search.split()

    },
  }
}
</script>

<style scoped>
/**********search_categories*******/
.ico_open{
  display:block;
  position: absolute;
  width:40px;
  height:40px;
  background:url("@/assets/img/burger.svg") no-repeat #444BD3;
  background-position: center center;
  border-radius:100%;
  right:10%;
  top:-20px;
  cursor:pointer;
}
.ico_close{
  display:block;
  position: absolute;
  width:40px;
  height:40px;
  background:url("@/assets/img/close.svg") no-repeat #444BD3;
  background-position: center center;
  border-radius:100%;
  right:10%;
  top:-20px;
  cursor:pointer;
}
.search_tittle {
  color:#111111;
  font-size:25px;
  font-weight: 500;
  margin-bottom:5px;

}
.search_categories {

}
.serch_categories_frist, .serch_categories_second{
  border-bottom: 1px solid #111111;
  position: relative;
}
.serch_categories_second, .serch_categories_frist, ul {
  list-style-type: none;
  padding:0px;
  font-size:20px;
}
.serch_categories_frist ul li{
  display: inline-block;
  margin-left:50px;
  cursor:pointer;
  color:#111111;
}
.serch_categories_frist ul li:first-child{
  display: inline-block;
  margin-left:0px;
}
.serch_categories_second ul li{
  display: inline-block;
  margin-left:50px;
  cursor:pointer;
  color:#111111;
}
.serch_categories_second ul li:first-child{
  display: inline-block;
  margin-left:0px;
}
.serch_categories_second .active{
  color:#444BD3;
  border-bottom:1px solid #444BD3;
}
.serch_categories_frist .active{
  color:#444BD3;
  border-bottom:1px solid #444BD3;
}
/**********search_categories*******/
.search_modules {
  color: #444BD3;
  border:1px solid #444BD3;
  border-radius:18px;
  padding:18px;
  font-size:20px;
  width:418px;
  background:url("@/assets/img/search.svg") no-repeat;
  background-size: 38px;
  background-position-x: 93%;
  background-position-y: 8px;
  margin-left: 45px;
  font-family: 'Montserrat';
}
.search_modules:focus{
  outline:none;
}
.search_modules::placeholder{
  color:#444BD3;
}
/*****modules_cart_magasine******/
.btn_magasin {
  margin:118px 0 69px 0;
  text-align:center;
  color:#fff;
}
.modules_cart_icon_2 {
  display:block;
  width:19px;
  height:19px;
  margin: 2px 0 0 10px;
  background:url("@/assets/img/line.svg") no-repeat;
  background-size: 100%;
}
.btn_magasin_a {
  padding:13px 116px;
  font-size:16px;
  background-color:#444BD3;
  border-radius:8px;
}
.modules_cart_magasine_p_2 {
  margin:0;
  padding-top:32px;
}
.modules_cart_magasine_prise_p {
  margin:0;
  font-size:20px;
}
.modules_cart_magasine {
  box-shadow: 4px 5px 12px 6px rgba(34, 60, 80, 0.40);
  margin:40px 0 10px 0;
}
.modules_cart_magasine_img {
  width:100%;
  height:auto;
}
.modules_cart_magasine_prise {
  display:flex;
  justify-content: space-between;
  padding: 32px 36px 33px 32px;
}
.modules_cart_magasine_prise_a {
  font-size:14px;
  color:#444BD3;
  padding-top:1px;
}
.modules_cart_magasine_prise_flex {
  display:flex;
  padding-top:3px;
}
.modules_cart_magasine_p_1 {
  margin:0px;
  font-size:20px;
  font-weight: 700;
  color:#444BD3;
}
.modules_cart_magasine_text {
  padding: 52px 8px 0 32px;
}

/*****modules_cart_magasine******/
.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg,#121127 ,90%, #E9F8FB);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #E9F8FB,83%, #FFF1F1);
  background-position: right;
}
/*****header_info*****/
.header_info {
  padding:0 0 147px 0;
}
.header_module_h3 {
  font-size:48px;
  line-height:57px;
  font-weight: 500;
  margin:140px 0 105px 0;
}
.find_cost {
  color:#fff;
  background:#444BD3;
  padding:13px 122px;
  border-radius:8px;
  margin-bottom:172px;
}
/*****header_info*****/

/*****all_products*****/
.mini_nav {
  margin:45px 0 59px 0;
}
.mini_nav_1 {
  font-size:18px;
}
.mini_nav_2 {
  color:#444BD3;
  font-size:18px;
}
.all_product_h3 {
  font-size:40px;
  font-weight:500;
}
.all_product_a {
  color: #444BD3;
  font-size:20px;
}
/*****all_products*****/

/*****modules_cart_magasine******/
.btn_magasin {
  margin:118px 0 69px 0;
  text-align:center;
  color:#fff;
}
.btn_magasin_a {
  padding:13px 116px;
  font-size:16px;
  background-color:#444BD3;
  border-radius:8px;
}
.modules_cart_magasine_p_2 {
  margin:0;
  padding-top:32px;
}
.modules_cart_magasine_prise_p {
  margin:0;
  font-size:20px;
}
.modules_cart_magasine {
  box-shadow: 4px 5px 12px 6px rgba(34, 60, 80, 0.40);
  margin-top:48px;
}
.modules_cart_magasine_img {
  width:100%;
  height:auto;
}
.modules_cart_magasine_prise {
  display:flex;
  justify-content: space-between;
  padding:74px 36px 33px 32px;
}
.modules_cart_magasine_prise_a {
  font-size:14px;
  color:#444BD3;
  padding-top:1px;
}
.modules_cart_magasine_prise_flex {
  display:flex;
  padding-top:3px;
}
.modules_cart_magasine_p_1 {
  margin:0px;
  font-size:20px;
  font-weight: 700;
  color:#444BD3;
}
.modules_cart_magasine_text {
  padding: 52px 8px 0 32px;
}

/*****modules_cart_magasine******/
@media (max-width:991px) {
.search_modules{
margin-left:0px;
width:100%;
margin-top: 20px;
}
.search_tittle {
  display:block;
}
.ico_open{
  display:block;
  position: absolute;
  width:40px;
  height:40px;
  background:url("@/assets/img/burger.svg") no-repeat #444BD3;
  background-position: center center;
  border-radius:100%;
  right:10%;
  top:-20px;
  cursor:pointer;
}
.ico_close{
  display:block;
  position: absolute;
  width:40px;
  height:40px;
  background:url("@/assets/img/close.svg") no-repeat #444BD3;
  background-position: center center;
  border-radius:100%;
  right:10%;
  top:-20px;
  cursor:pointer;
}
.serch_categories_frist ul li{
  display: table;
  margin-left:0px;
  margin-top:18px;
}
.serch_categories_second ul li {
  display: table;
  margin-left:0px;
  margin-top:18px;
}
.serch_categories_frist ul li:first-child{
  margin-top:0px;
}
.serch_categories_second ul li:first-child{
  margin-left:0px;
  margin-top:0px;
}
}
@media (max-width:439px) {
  .find_cost {
    padding:13px 47px;
  }
}
</style>