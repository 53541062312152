<template>
    <div class="SimilarModules">
        <div class="box">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="SimilarModules_h3 text-color">Похожие модули</h3>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <product_cart/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                <product_cart/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <product_cart/>
                </div>
          </div>  
        </div>
    </div>
</template>

<script>
import product_cart from '@/components/product_cart.vue'
export default {
  name: 'SimilarModules',
  components: {
    product_cart,
  }
}
</script>
<style scoped>
/*****SimilarModules******/
.SimilarModules_h3 {
    font-size:30px;
    margin:70px 0 26px 0;
}
/*****SimilarModules******/
</style>