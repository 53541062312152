<template>
  <div class="home_info">
        <div class="box">
          <div class="row">
            <div class="col-lg-5">
              <div class="home_info_box">
                <h3 class="home_h3 text-color">Разработка «под ключ» и готовые решения для бизнеса</h3>
                <p class="home_p text-color">От небольшого модуля доразработки корпоративного портала</p>
                <a class="home_btn">Узнать стоимость</a>
              </div>
            </div>
        </div>             
    </div>
  </div>
</template>

<script>

</script>

<style scoped>

.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #121127 50%, #BAA2FF 50%);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #EDF9FF);
  background-position: right;
}
/*****home_info******/
.home_info_box {
  margin-top:150px;
  padding-bottom:126px;
}
.home_h3 {
  font-size:48px;
  line-height:120%;
  font-weight:500;
  margin:0;
}
.home_p {
  font-size:26px;
  margin:39px 0 76px 0;
}
.home_btn {
  padding: 13px 122px;
  background-color:#444BD3;
  color:#fff;
  border-radius:6px;
}
@media(max-width:991px) {
  .home_h3 {
    font-size:35px;
  }
  .home_p {
    font-size: 19px;
    margin: 35px 0 55px 0;
  }
  .home_btn {
    padding: 13px 50px;
  }
}
/*****home_info*****/
</style>