<template>
    <div class="UnderNavImpro">
        <div class="box box_undernav">
            <div class="row">
                <div class="col-md-4">
                    <p class="text-color undernav_a undernav_a_active">Описание услуги</p>
                </div>
                <div class="col-md-4">                
                    <p class="text-color undernav_a">Что входит в услугу</p>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>

</script>

<style scoped>

/*****UnderNavImpro*****/
.undernav_a_active{
    color:#444BD3;
    text-decoration: underline;
}
.undernav_a {
    font-size:30px;
    line-height:35px;
    font-weight:400;
    cursor:pointer;
    text-align:center;
    margin:40px;
}
.white .box_undernav {
    width:100%;
    border-bottom:1px solid #000;
}
.black .box_undernav {
    width:100%;
    border-bottom:1px solid #fff;
}
@media(max-width:1271px){
    .undernav_a {
        font-size:23px;
    }
}
@media(max-width:767px){
    .undernav_a {
        margin:20px 0 20px 0;
    }
}
/*****UnderNavImpro*****/
</style>