<template>
    <div class="ImprovementsPageHeader">
        <div class="background">
            <div class="box">
               <div class="row">
                   <div class="col-md-6">
                       <h3 class="header_h3 text-color">Натяжка готовой верстки</h3>
                       <p class="header_p_1 text-color">Битрикс24</p>
                       <p class="header_p_2 text-color">Цена 1300 р/ч</p>
                       <a class="header_a">Узнать стоимость</a>
                   </div>
                   <div class="col-md-6">
                       <img src="../../assets/img/Product_page/start.svg" class="header_img">
                   </div>
               </div> 
            </div>
        </div>
    </div>
</template>

<script>
import navigation from '@/components/navigation.vue'
export default {
  name: 'HeaderProductCart',
  components: {
    navigation,
  }
}
</script>

<style scoped>

/*****ImprovementsPageHeader*****/
.background {
    background:#FFAF83;
}
.header_h3 {
    font-size:48px;
    font-weight:500;
    line-height:57px;
    margin:90px 0 0 0;
}
.header_p_1 {
    font-size:20px;
    line-height:23px;
    margin:23px 0;
    text-decoration: underline;
}
.header_p_2 {
    font-size:30px;
    line-height:35px;
    margin:0 0 60px 0;
}
.header_h2 {
    font-size:48px;
    line-height:57px;
    font-weight:bold;
    max-width:550px;
    margin:0;
}
.header_a {
    padding: 13px 122px;
    background-color:#444BD3;
    color:#fff;
    border-radius:6px;
}
.header_img {
    width:100%;
    height:auto;
    margin:60px 0;
}
/*****ImprovementsPageHeader*****/
</style>