<template>
    <div class="UnderNav">
        <div class="box box_undernav">
            <div class="row">
                <div class="col-md-4" v-for="(nav, index) of this.nav" :key='index'>
                    <p @click="active_nav = index" class="text-color undernav_a" :class="{undernav_a_active:active_nav == index}" >{{nav.name}}</p>
                </div>
            </div> 
        </div>
    </div>
        <div class="SliderProductCart" v-if="active_nav === 0">
        <div class="box">
            <div class="row">
                <div class="offset-md-1 col-md-4">
                    <h3 class="text-color slider_h3">The modern way to build for the web</h3>
                    <p class="slider_p">Dlex empowers designers to build professional, custom websites in a completely visual canvas with no code.</p>
                    <a class="slider_a">Get Started</a>
                </div>
                <div class="offset-md-2 col-md-4">
                    <img src="../../assets/img/Product_page/telefon.svg" class="slider_img">
                </div>
            </div>
        </div>
    </div>
    <div class="IncludedServises" v-if="active_nav === 1">
        <div class="box">
            <div class="row">
                <div class="col-md-6">
                    <div class="box_general">
                        <div class="flex">
                            <p class="general_p text-color">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                            <img src="../../assets/img/Product_page/line.svg" class="general_img">
                        </div>
                        <div class="box_worker">
                            <p class="worker_p">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is    Workspace.</p>  
                        </div>
                    </div>
                    <div class="box_general">
                        <div class="flex">
                            <p class="general_p text-color">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                            <img src="../../assets/img/Product_page/line.svg" class="general_img">
                        </div>
                        <div class="box_worker">
                            <p class="worker_p">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is    Workspace.</p>  
                        </div>
                    </div>
                    <div class="box_general">
                        <div class="flex">
                            <p class="general_p text-color">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                            <img src="../../assets/img/Product_page/line.svg" class="general_img">
                        </div>
                        <div class="box_worker">
                            <p class="worker_p">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is    Workspace.</p>  
                        </div>
                    </div>
                    <div class="box_general">
                        <div class="flex">
                            <p class="general_p text-color">Can I upgrade myself or do I have to upgrade my entire Workspace?</p>
                            <img src="../../assets/img/Product_page/line.svg" class="general_img">
                        </div>
                        <div class="box_worker">
                            <p class="worker_p">To upgrade Dlex, you’ll need to upgrade your entire Workspace, which means all members in your Workspace. The top level of organization is    Workspace.</p>  
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="../../assets/img/Product_page/tell.svg" class="included_img">
                </div>
            </div>
        </div>
    </div>
        <div class="Readiness" v-if="active_nav === 2">
        <div class="box">
            <div class="row">
                <div class="col-md-5">
                    <h1 class="text-color readiness_h1">Ready to dive in? Start your free trial today.</h1>
                    <p class="text-color slider_p">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint velit officia consequat duis enim velit mollit.</p>
                    <a class="slider_a">Sign up for free</a>
                </div>
                <div class="offset-md-1 col-md-6">
                    <img src="../../assets/img/Product_page/start.svg" class="readiness_img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'ProductPage',
    data(){
        return {
            nav:[{'name':'Описание модуля'}, {'name':'Что входит в услугу'}, {'name':'Презентация'}],
            active_nav:0,
        }
    }
}
</script>

<style scoped>
/*****UnderNav******/
.undernav_a_active{
    color:#444BD3;
    text-decoration: underline;
}
.undernav_a {
    font-size:30px;
    line-height:35px;
    font-weight:400;
    cursor:pointer;
    text-align:center;
    margin:40px;
}
.white .box_undernav {
    width:100%;
    border-bottom:1px solid #000;
}
.black .box_undernav {
    width:100%;
    border-bottom:1px solid #fff;
}
@media(max-width:1271px){
    .undernav_a {
        font-size:23px;
    }
}
@media(max-width:767px){
    .undernav_a {
        margin:20px 0 20px 0;
    }
}
/*****UnderNav******/
/*****Readiness******/
.black .slider_p {
    color:#fff;
    opacity:56%;
    font-size:18px;
    line-height:36px;
}
.white .slider_p {
    color:#121127;
    opacity:56%;
    font-size:18px;
    line-height:36px;
    margin:12px 0 36px 0;
}
.slider_a {
    background:#444BD3;
    border-radius:6px;
    color:#fff;
    padding:14px 32px;
}
.readiness_h1 {
    font-size:40px;
    font-weight:700;
    line-height:56px;
}
.Readiness {
    margin-top:86px;
}
.readiness_img {
    width:100%;
    height:auto;
    margin-top:30px;
}
/*****Readiness******/
/*****IncludedServises******/
.general_img {
    width:20px;
    height:20px;
    cursor:pointer;
    margin-top:24px;
}
.box_general {
    border-bottom:1px solid #909090;
}
.general_p {
    font-size:20px;
    font-weight:700;
    line-height:28px;
    padding-right:30px;
}
.worker_p {
    margin:16px 0 25px 0;
}
.white .worker_p {
    color:rgba(18, 17, 39, 0.56);
}
.black .worker_p {
    color:#fff;
    opacity:70%;
}
.included_img {
    width:100%;
    height:auto;
    margin-top:56px;
}
.IncludedServises {
    margin-top:86px;
}
@media(max-width:)
/*****IncludedServises******/
/*****SliderProductCart******/
.slider_h3 {
    font-size:56px;
    font-weight:700px;
    line-height:67px;
    margin:170px 0 0 0;
}
.black .slider_p {
    color:#fff;
    opacity:56%;
    font-size:20px;
    line-height:36px;
}
.white .slider_p {
    color:#121127;
    opacity:56%;
    font-size:20px;
    line-height:36px;
    margin:12px 0 36px 0;
}
.slider_a {
    background:#444BD3;
    border-radius:6px;
    color:#fff;
    padding:14px 32px;
}
.slider_img {
    height:auto;
    width:100%;
    margin-top:60px;
}
@media(max-width:1188px) {
    .slider_h3 {
        font-size:40px;
        line-height:50px;
        margin-top:67px;
    }
}
@media(max-width:880px) {
    .slider_h3 {
        font-size:30px;
    }
}
/*****SliderProductCart******/
</style>