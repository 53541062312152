<template>
  <div class="home_page_services">
    <div class="box">
        <div class="row">
          <div class="col-md-6">
            <div class="magasin_services">
            <h3 class="magasin_head_h3 text-color">Наши услуги</h3>
            <p class="text-color progects_p">Выберите нужную вам услугу или решение и мы выполним поставленную вашу задачу, у нас есть как и готовые решения так и опыт решать многие бизнес процессы под ключ.</p>
            <div class="magasin_services_under">
              <a class="magasin_services_a_1">Посмотреть весь каталог <i class="modules_cart_icon_2"></i></a>
              <div class="magasin_services_under_1"><a class="magasin_services_a_2">Связаться с нами<i class="modules_cart_icon_2 icon-color_2"></i></a></div>
            </div>
            </div>
          </div>
          <div class="col-md-3 home_page_services_box_under">
            <div class="d19"></div>
            <p class="magasin_services_rigcht_p_1 text-color">Натяжка готовой верстки</p>
            <p class="magasin_services_rigcht_p_2 text-color">Перенесем дизайн на любую CMS в короткие сроки</p>
            <a class="magasin_services_rigcht_a">Посмотреть детали<i class="modules_cart_icon_2"></i></a>
          </div>
          <div class="col-md-3 home_page_services_box_under">
            <div class="d19"></div>
            <p class="magasin_services_rigcht_p_1 text-color">Натяжка готовой верстки</p>
            <p class="magasin_services_rigcht_p_2 text-color">Перенесем дизайн на любую CMS в короткие сроки</p>
            <a class="magasin_services_rigcht_a">Посмотреть детали<i class="modules_cart_icon_2"></i></a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

</script>

<style scoped>
.black .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #121127);
  background-position: right;
}
.white .background_gradient {
  background:url("@/assets/img/header_img_2.webp") no-repeat, linear-gradient(119deg, #BAA2FF,10%, #EDF9FF);
  background-position: right;
}


.black .our_achievements {
    background:linear-gradient(to right, #121127 0%, #121127 calc(80% - 172px), transparent calc(212% - 122px), transparent calc(115% + 41px), #121127 calc(66% + 160px), #121127 112%), url('@/assets/img/some/display_2.svg') no-repeat;
  background-position:right;
}
.white .our_achievements {
    background:linear-gradient(to right, #fff 0%, #fff calc(80% - 166px), transparent calc(101% - 114px), transparent calc(102% + 30px), #fff calc(50% + 160px), #fff 100%), url('@/assets/img/some/display_2.svg') no-repeat;
  background-position:right;
}
/*****services******/
.modules_cart_icon_2 {
  display:block;
  width:19px;
  height:19px;
  margin: 2px 0 0 10px;
  background:url("@/assets/img/line.svg") no-repeat;
  background-size: 100%;
}
.progects_p {
  Line-height:32px;
  font-size:18px;
  margin:32px 0 38px 0;
}
.magasin_services_rigcht_a {
  display:flex;
  color:#444BD3;
}
.magasin_services_rigcht_p_2{
  margin-bottom:24px;
}
.magasin_services_rigcht_p_1 {
  margin:24px 0 16px 0;
  font-size:18px;
  font-weight:700;
}
.magasin_services {
  max-width:490px;
}
.magasin_services_under_1 {
  display:flex;
}
.magasin_services_under {
  display:flex;
  justify-content: space-between;
}
.magasin_services_a_2 {
  padding-top:14px;
  display:flex;
  color:#444BD3;
}
.magasin_services_a_1 {
  display:flex;
  padding:14px 20px;
  background:#444BD3;
  color:#fff;
  border-radius:6px;
}
.d19 {
    width: 36px;
    height: 36px;
    background: linear-gradient(210deg, #4C1D95,55%, #ABD2FF);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    margin: 15px 0px 0px 25px;
    border-radius:4px;
}
/*****services******/

@media(max-width:767px) {
  .home_page_services_box_under {
    margin-top:30px;
  }
}
@media(max-width:950px) {
  .magasin_services_under {
    display:grid;
  }
  .magasin_services_a_2 {
    padding-left:21px;
  }
}
</style>