import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Improvements from '../views/ImprovementsPageView.vue'
import ProductPage from '../views/ProductPageView.vue'
import ShopService from '../views/ShopServiceView.vue'
import shopModule from '../views/shopModuleView.vue'
import CmsSistems from '../views/CmsSistemsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Improvements',
    name: 'Improvements',
    component: Improvements
  },
  {
    path: '/ProductPage',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/ShopService',
    name: 'ShopService',
    component: ShopService
  },
  {
    path: '/shopModule',
    name: 'shopModule',
    component: shopModule
  },
  {
    path: '/CmsSistems',
    name: 'CmsSistems',
    component: CmsSistems
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
