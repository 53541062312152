<template>
  <div class="why_we">
    <div class="box">
        <div class="row">
          <div class="col-lg-4">
            <p class="why_we_p_1">МАГАЗИН МОДУЛЕЙ</p>
            <h3 class="why_we_h_1 text-color">Почему наши модули?</h3>
            <p class="why_we_p_2 text-color">Lorem ipsum dolor sit amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint velit officia consequat duis enim velit mollit.</p>
          </div>
          <div class="col-lg-4 text-color">
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
          </div>
          <div class="col-lg-4 text-color">
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
            <div class="why_we_arguments flex">
              <i class="why_we_arguments_i"></i>
              <p class="why_we_arguments_p">Устанавливаются в 2 клика</p>
            </div>
          </div>
      </div>
      </div>
  </div>
</template>

<script>

</script>

<style scoped>
/*****why_we******/
.why_we_p_1 {
  color:#444BD3;
  font-size:14px;
  line-height:24px;
  font-weight:bold;
  margin:0;
}
.why_we_h_1 {
  font-size:29px;
  line-height:45px;
  margin:8px 0 20 0;
}
.why_we_p_2 {
  opacity:50%;
  margin:0;
  font-size:16px;
}
.why_we_arguments_i {
  display: block;
  width: 16px;
  height: 16px;
  background: url('@/assets/img/galochka.png');
  background-size: 100%;
}
.why_we_arguments_p {
  margin:0 0 0 18px;
}
.why_we_arguments {
  margin-top:20px;
  padding-bottom:20px;
  border-bottom:solid #808080 1px;
}
.why_we {
  margin-top:115px;
  padding-bottom:115px;
}
/*****why_we******/
</style>