<template>
  <div class="development">
    <div class="development_cart">
      <div class="development_box_under">
        <img src="../assets/img/services-img1.png" class="development_box_img">
      </div>
        <p class="text-color development_cart_1">НАПОЛНЕНИЕ САЙТА КОНТЕНТОМ</p>
        <div class="flex justify-content">
          <p class="text-color development_cart_2">26 000 руб.</p>
          <p class="development_cart_3">ПОДРОБНЕЕ<i class="modules_cart_icon_2"></i></p>
        </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
.modules_cart_icon_2 {
  display:block;
  width:19px;
  height:19px;
  margin: 2px 0 0 10px;
  background:url("../assets/img/line.svg") no-repeat;
  background-size: 100%;
}
.development_cart_3 {
  display:flex;
  color:#444BD3;
}
.development_box_img {
  width:auto;
  height: 214px;
  float:right;
}
.development_box_under {
  height:214px;
  background:#8B5CF6;
}
.development_cart {
  padding-top:50px;
}
</style>